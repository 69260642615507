import { get, create, update } from '../../lib/request'
import { Meta, UrlQueryItem } from '../api';

// Legal Entity
export interface AssetItem { 
  id: number;
  key?: string
  name?: string
  type?: string
  comment?: string
  }

export interface AssetListResponse {
  data?: AssetItem[];  
  meta?: Meta;
}

export const getAssetsList = ( query: UrlQueryItem[] = [], token?: string ) => {
  const queryString = query ? query.reduce( (value, item) => item.value != '' ? (item.field + '=' + item.value + '&' + value): value, "" ) : ""
  console.log(queryString)
  return get<AssetItem[]>(`/assets${ queryString != '' ? '?' + queryString : ""}`, token);
};


export const lookupAssets = ( queryString: string = '' ) => {
  return get<AssetItem[]>(`/assets/lookup/${ queryString}`);
};

export const getAsset = (id: string) => {
  return get<AssetItem>(`/asset/${id}`);
};

export const createAsset = ( data:AssetItem ) => {
  return create<AssetItem>(`/asset`, data);
};

export const updateAsset = ( data: AssetItem) => {
    return update<AssetItem>(`/asset/${data.id}`, data);
};

