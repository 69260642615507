export function getStorage<T>(key: string): T;
export function getStorage<T>(key: string, conf: { json: false }): string;
export function getStorage<T>(key: string, conf: { json: true }): T;
export function getStorage<T>(key: string, conf?: { json: boolean }) {
    const { json = true } = conf || {};
    try {
        const value = localStorage.getItem(key) as string;
        return json ? (JSON.parse(value) as T) : value;
    } catch (e) {
        return null;
    }
}

export function setStorage<T>(key: string, value: T): void;
export function setStorage<T>(key: string, value: T, conf: { json: true }): void;
export function setStorage<T>(key: string, value: string, conf: { json: false }): void;
export function setStorage<T>(key: string, value: T | string, conf?: { json: boolean }): void {
    const { json = true } = conf || {};
    try {
        localStorage.setItem(key, json ? JSON.stringify(value) : (value as string));
    } catch (e) {}
}

export function removeStorage(key: string): void {
    try {
        localStorage.removeItem(key);
    } catch (e) {}
}
