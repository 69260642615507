import { Box, Tabs, Tab, Stack, TextField, Typography, Select, Button } from "@mui/material"
import { ChangeEventHandler, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { UrlQueryItem } from "../../../api/api";


export interface DbgFilterAreaField {
    label: string
    fieldtype: "text" | "select"
    name: string
}

export interface DbgSearchFilterField {
    field: string
    value: string
}

export interface DbgFilterAreaProps {
    caption: string;
    fields: DbgFilterAreaField[]
    query: UrlQueryItem[]
    onFilter: ( filter: any ) => void
}

function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

export const DbgFilterArea = ( props: DbgFilterAreaProps ) => {

    const [urlQuery, setUrlQuery] = useState<UrlQueryItem[]>( []);
    // const [filter, setFilter] = useState<DbgSearchFilterField[]>([]);
    const navigate = useNavigate();

    const handleChange = (event: any, fieldName: string) => {

        let newUrlQuery = urlQuery
        const newItem = { field: fieldName, value: event.target.value}

        if (! newUrlQuery) {
            newUrlQuery = [newItem]
        } else {
            const found = newUrlQuery.find((element) => element.field == fieldName);

            if (!found ) {
                newUrlQuery.push(newItem)
            } else {
                newUrlQuery = newUrlQuery.map( (item) => item.field == fieldName ? newItem : item )
            }
            
        }

        setUrlQuery (newUrlQuery)
        props.onFilter(newUrlQuery)

        // newUrlQuery.fieldName

        // let newFilter = filter
        // const found = newFilter.find((element) => element.field == fieldName);
        // const newItem = { field: fieldName, value: event.target.value}

        // if (!found ) {
        //     newFilter.push( newItem)
        // } else {
        //     newFilter = newFilter.map( (item) => item.field == fieldName ? newItem : item )
        // }
        
        // setFilter(newFilter)
        // props.onFilter(newFilter)
    };

    let i = 0

    return (
        <Box sx={{ mx: 2, my: 0 }}>
          <Typography padding={2}  sx={{textAlign: 'left', fontWeight: 600, pt: 2}} color="black" noWrap>{props.caption}</Typography>

          <Stack sx={{ pb: 2 }} direction="row" display="flex" flexDirection="row" paddingLeft={2} paddingBottom={4} spacing={{ xs: 1, sm: 2, md: 2 }}>

        { props.fields.map( (item) =>  
            
            item.fieldtype == "text" ?
            <Stack direction="column" sx={{width: '200px'}}>
                <Typography sx={{color: '#686c6e', fontSize: '14px', textAlign: 'left', fontWeight: 600}} color="black" noWrap>
                    {item.label}
                </Typography>
                <TextField onChange={ (event) => {  handleChange(event, item.name) }} hiddenLabel size='small' id="filled-basic" variant="filled" sx={{borderBottomLeftRadius: '15px'}}/>
            </Stack> 
            :
            <Stack direction="column" sx={{width: '200px'}}>
            <Typography sx={{color: '#686c6e', fontSize: '14px', textAlign: 'left', fontWeight: 600}} color="black" noWrap>
            {item.label}
            </Typography>
            <Select hiddenLabel size='small' id="filled-basic" variant="filled" sx={{borderBottomLeftRadius: '15px'}}/>
            </Stack>

)} 



</Stack>

  </Box>

    )
}