import { Chip, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { StatusItem } from "../../api/Status";


interface StatusTagProps {
statusItem: StatusItem
}


export function StatusTag( props: StatusTagProps) {

    return (
        <Chip label={props.statusItem.name} sx={{ bgcolor: props.statusItem.color, color: 'auto'}} />
)

}