import { useState, Fragment, FC, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Divider, FormControl, Grid, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Stack, SxProps, Typography } from '@mui/material';
import { useNavigate, useParams } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form"
import { createCryptoClearance, getCryptoClearance, updateCryptoClearance } from '../../api/CryptoClearance';
import React from 'react';
import { TypeToSearchSelectBox } from '../../components/TypeToSearchSelectBox';

type FormValues = { 
  cryptoAsset: string
    tradeVolumeEur: number
    orderType: string
    comment: string
    }

const defaultFormValues:FormValues = 
{
  cryptoAsset: "" ,
    tradeVolumeEur: 0,
    orderType: "" ,
    comment: "" ,
    }

type CryptoClearanceFormProps = {
  onChanged: () => void
}

export default function CryptoClearanceForm( props: CryptoClearanceFormProps) {

  //const [ open, setOpen ] = useState(true)
  const navigate = useNavigate()
  const { id } = useParams()  
  const [ thisId, setThisId ] = useState ( id )

  if (!thisId) { setThisId("new") } 
   
  const { getValues, register, handleSubmit, setValue, formState: { errors }, } = useForm<FormValues>( )
  const [ data, setData ] = useState<FormValues>(defaultFormValues)
  const [ isLoading, setIsLoading ] = useState( id != 'new' )
  
  const onSubmit: SubmitHandler<FormValues> = (data: FormValues) => {
     setData(data)

     if (thisId == 'new') {
       createCryptoClearance( {  ...data, id: 0} ).then( ( json ) => { 
         setThisId( json.data.id.toString() )
       })
     } else {
       updateCryptoClearance( { ...data, id: Number(thisId)  } )
     }
  }

  useEffect ( () =>{
     if ( thisId != 'new' ) {
      getCryptoClearance(thisId!).then((json) => {
        console.log(json);
        //setValue( {...json.data} )
        //update(0, json.data)

        setValue( "cryptoAsset", json.data.cryptoAsset || "" )
          setValue( "tradeVolumeEur", json.data.tradeVolumeEur || 0)
          setValue( "orderType", json.data.orderType || "" )
          setValue( "comment", json.data.comment || "" )
          // setValue( "comment", json.data.comment || "" )
        setData( json.data as FormValues)
        setIsLoading(false)
      }).finally(() => setIsLoading(false));
    }
    }, []);

  const OnDialogClose = () => {
    return false;
  }

  const handleClose = () => {
    //setOpen(false);
    navigate ('/crypto-clearances' )
    //props.onChanged()
  };
  


  const sx: SxProps = {
    
    "& .MuiDialog-container": {
      alignItems: "flex-start",
      marginTop: 15
    }
 };

  return (
    <Fragment>

        { !isLoading && 
        
        <Dialog
          sx={sx}    
          open={true}
          onClose={ OnDialogClose}
          maxWidth='sm'
        >
          
        <form onSubmit={handleSubmit(onSubmit)}>        

        <DialogTitle>{ id=='new' ? 'New Crypto Clearance' : 'Crypto Clearance'}</DialogTitle>
        <Divider />
        <DialogContent sx={{  bgcolor : '#f5f6f7', pt:0}}>

          <Typography variant='h6' sx={ {my: 2}} >Trade information</Typography>
          <Typography sx={ {mb: 2}} >Please tell us more about your planned crypto trade?</Typography>
          <Paper sx={{ p:2 }} elevation={1}>
          <Box>

         <TypeToSearchSelectBox onSelect={ ( value ) => setValue( "cryptoAsset", value) } />
                     
            <TextField
              sx={{ width: '100%', bgcolor: '#FFFFFF' }}
              required
              autoFocus
              margin="dense"
              id="name"
              label="Trade Volume (EUR)"
              type="text"
              variant="filled"
              {...register("tradeVolumeEur")}
            />
            <TextField
              sx={{ width: '100%', bgcolor: '#FFFFFF' }}
              required
              autoFocus
              margin="dense"
              id="name"
              label="Order Type"
              type="text"
              variant="filled"
              {...register("orderType")}
            />
            </Box>

          </Paper>

          <Typography variant='h6' sx={ {my: 2}} >Notes & Attachments</Typography>
          <Typography sx={ {mb: 2}} >If you have further information, you can provide them here</Typography>
          <Paper sx={{ p:2 }} elevation={1}>
          <Box>


            <TextField
              sx={{ width: '100%', bgcolor: '#FFFFFF' }}
              required
              autoFocus
              margin="dense"
              id="name"
              label="Notes / Comments"
              type="text"
              variant="filled"
              {...register("comment")}
            />
            </Box>

          </Paper>

          </DialogContent>

        <DialogActions sx={{ px: 3, py: 2}}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" variant='contained' >Save</Button>
        </DialogActions>
      </form>
      </Dialog> 
  
        }
    </Fragment>
  );
}
