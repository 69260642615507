import { AppBar, Avatar, Card, CardHeader, Stack, Toolbar, Typography } from "@mui/material";
import logo from './CompanyLogo.svg';
import './header.css';
import { useAuth0 } from "@auth0/auth0-react";

export const DbgHeader = () => {
    
    const { user } = useAuth0(); 



    return (
     <AppBar position="relative" style={{ backgroundColor: '#FFF', boxShadow: 'none' }}>
      <Toolbar sx={{justifyContent: 'space-between'}}>
      
        <Stack direction="row">
          <img src={logo} className="App-logo" alt="logo" />
          <Typography variant="h6" sx={{ fontWeight: 400, marginLeft: '40px' }} color="black" noWrap>
            Personal Crypto Dealings
          </Typography>

        </Stack>
        
        <Stack sx={{float: 'right'}} spacing={2} direction="row" >
          <Stack alignItems="flex-end" >
            <Typography variant="body2" sx={{color: 'black'}} >{localStorage.getItem('name')}</Typography>
            <Typography variant="body2" sx={{color: 'black'}}>{localStorage.getItem('activeRole')}</Typography>
          </Stack>
          <Avatar alt="" src={user?.picture}sx={{ bgcolor: '#500', float: 'right' }} aria-label="recipe"></Avatar>
        </Stack>

      </Toolbar>
    </AppBar> )
 
}

