import { useState, Fragment, FC, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Divider, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Stack, SxProps, Typography } from '@mui/material';
import { useNavigate, useParams } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form"
import { createExampleEntity, getExampleEntity, updateExampleEntity } from '../../api/ExampleEntity';

type FormValues = {

  
  firstName: string
    lastName: string
    cityOfBirth: string
    }

const defaultFormValues:FormValues = 
{

  firstName: "",
    lastName: "",
    cityOfBirth: "",
    }

type ExampleEntityFormProps = {
  onChanged: () => void
}

export default function ExampleEntityForm( props: ExampleEntityFormProps) {

  //const [ open, setOpen ] = useState(true)
  const navigate = useNavigate()
  const { id } = useParams()  
  const [ thisId, setThisId ] = useState ( id )

  if (!thisId) { setThisId("new") } 
   
  const { getValues, register, handleSubmit, setValue, formState: { errors }, } = useForm<FormValues>( )
  const [ data, setData ] = useState<FormValues>(defaultFormValues)
  const [ isLoading, setIsLoading ] = useState( id != 'new' )
  
  const onSubmit: SubmitHandler<FormValues> = (data: FormValues) => {
     setData(data)

     if (thisId == 'new') {
       createExampleEntity( {  ...data, id: 0} ).then( ( json ) => { 
         setThisId( json.data.id.toString() )
       })
     } else {
       updateExampleEntity( { ...data, id: Number(thisId)  } )
     }
  }

  useEffect ( () =>{
     if ( thisId != 'new' ) {
      getExampleEntity(thisId!).then((json) => {
        console.log(json);
        //setValue( {...json.data} )
        //update(0, json.data)

        setValue( "firstName", json.data.firstName || "" )
          setValue( "lastName", json.data.lastName || "" )
          setValue( "cityOfBirth", json.data.cityOfBirth || "" )
          // setValue( "comment", json.data.comment || "" )
        setData( json.data as FormValues)
        setIsLoading(false)
      }).finally(() => setIsLoading(false));
    }
    }, []);

  const OnDialogClose = () => {
    return false;
  }

  const handleClose = () => {
    //setOpen(false);
    navigate ('/example-entities' )
    //props.onChanged()
  };


  const sx: SxProps = {
    
    "& .MuiDialog-container": {
      alignItems: "flex-start",
      marginTop: 15
    }
 };

  return (
    <Fragment>

        { !isLoading && 
        
        <Dialog
          sx={sx}    
          open={true}
          onClose={ OnDialogClose}
          maxWidth='sm'
        >
          
        <form onSubmit={handleSubmit(onSubmit)}>        

        <DialogTitle>{ id=='new' ? 'New Example Entity' : 'Example Entity'}</DialogTitle>
        <Divider />
        <DialogContent sx={{  bgcolor : '#f5f6f7', pt:0}}>

          <Typography variant='h6' sx={ {my: 2}} >Your Personal Information</Typography>
          <Typography sx={ {mb: 2}} >Please tell us more about you. What's your name? How would you like to be called? What's your name? How would you like to be called? What's your name? How would you like to be called?</Typography>
          <Paper sx={{ p:2 }} elevation={1}>
          <Box>


            <TextField
              sx={{ width: '100%', bgcolor: '#FFFFFF' }}
              required
              autoFocus
              margin="dense"
              id="name"
              label="First Name"
              type="text"
              variant="filled"
              {...register("firstName")}
            />
            <TextField
              sx={{ width: '100%', bgcolor: '#FFFFFF' }}
              required
              autoFocus
              margin="dense"
              id="name"
              label="Last Name"
              type="text"
              variant="filled"
              {...register("lastName")}
            />
            </Box>

          </Paper>

          <Typography variant='h6' sx={ {my: 2}} >About your City of Birth</Typography>
          <Typography sx={ {mb: 2}} >Birth is important. Without birth you would not be entering this form. Got it? Without birth you would not be entering this form. Got it?</Typography>
          <Paper sx={{ p:2 }} elevation={1}>
          <Box>


            <TextField
            required
            autoFocus
            multiline
            margin="dense"
            id="description"
            label="City of Birth"
            type="text"
            fullWidth
            variant="filled"
            {...register("cityOfBirth")}
            rows={4}
            //maxRows={4}
            />

            </Box>

          </Paper>

          </DialogContent>

        <DialogActions sx={{ px: 3, py: 2}}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" variant='contained' >Save</Button>
        </DialogActions>
      </form>
      </Dialog> 
  
        }
    </Fragment>
  );
}
