import CheckBox from "@mui/icons-material/CheckBox"
import { useNavigate } from 'react-router-dom';

export interface LegalEntityBoxProps {

    name: string
    id: number
    x: number
    y: number
    status: string
    indent?: boolean

}

export const LegalEntityBox = (props: LegalEntityBoxProps) => {

    const navigate = useNavigate();
    const widthBox = 180

    const handleOpenClick = ( id:number ) => {
        navigate('/organigram/' + id)
    }

    return (
        <svg>
            <foreignObject onClick={() => { handleOpenClick( props.id ) }} style={{ textAlign: 'left', paddingLeft: props.indent ? 20 : 10 }} x={props.x} y={props.y} width={widthBox} height="100">
                <div style={{ fontSize: 11, paddingBottom: 7 }}>100%</div>
                <div className="card__background">
                    <CheckBox onClick={() => { alert('clicked') }} sx={{ position: 'absolute', bottom: 10, right: 10, color: props.status === 'active' ? 'green' : 'red' }} />
                    <p className="card__text">{props.name}</p>
                    <div style={{ width: '100%' }}>
                    </div>
                </div>
            </foreignObject>
        </svg>)

}