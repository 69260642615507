import { Autocomplete, Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import React, { useDeferredValue, useEffect } from "react";
import { lookupAssets } from "../../api/Asset";

interface TypeToSearchSelectBoxProps {
    onSelect: (value: string) => void;
    value?: string
}

interface SelectItemType {
    id: number;
    name: string;
}

export function TypeToSearchSelectBox( props: TypeToSearchSelectBoxProps) {

    const [value, setValue] = React.useState<SelectItemType | null>(null);
    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState<readonly SelectItemType[]>([]);
    const [selectedOption, setSelectedOption] = React.useState<SelectItemType>();

    //const [options, setOptions] = React.useState<string[]>([]);


    const handleChange = (event: SelectChangeEvent) => {
        console.log('handleChange')
        // setAge(event.target.value);
      };
    
useEffect( () => {
    setOptions ( [] )
    //setOptions ( [ "Bitcoin", "Ethereum"] )
    //setValue( {id: 1, name: 'Bitcoin'} )
}, [] )

    return (
    <Autocomplete 
        renderInput={ (params) => <TextField {...params} variant="filled" label="Search for a Crypto Asset" fullWidth /> }
        renderOption={(props, option) => <Box {...props} component={'li'} >{option.name}</Box> }
        sx={{ mb: 1}}
        options={options}
        getOptionLabel={(option) => option.name}
        //getOptionKey={ (value) => { console.log( value) }}
        onChange={(e, value) => { 
            if (value) {
                setSelectedOption(value as SelectItemType) 
                props.onSelect( value!.name)
            }
        }}
        onInputChange={ (e, value) => { 
            if (value) lookupAssets(value).then( (json) => {  setOptions(json.data as SelectItemType[]) })
        } }
        onSelect={ ( event ) => {
           
           
        } }
    />)

}