import { get, create, update } from '../../lib/request'
import { Meta, UrlQueryItem } from '../api';

// Legal Entity
export interface TradingVenueItem { 
  id: number;
  name: string;
  userId?: number;
  account: string;
  createdAt?: string;
  updatedAt?: string;
  comment?: string;
}

export interface TradingVenueListResponse {
  data?: TradingVenueItem[];  
  meta?: Meta;
}

export const getTradingVenuesList = ( query: UrlQueryItem[] = [] ) => {
  const queryString = query ? query.reduce( (value, item) => item.value != '' ? (item.field + '=' + item.value + '&' + value): value, "" ) : ""
  console.log(queryString)
  return get<TradingVenueItem[]>(`/trading-venues${ queryString != '' ? '?' + queryString : ""}`);
};

export const getTradingVenue = (id: string) => {
  return get<TradingVenueItem>(`/trading-venue/${id}`);
};

export const createTradingVenue = ( data:TradingVenueItem ) => {
  return create<TradingVenueItem>(`/trading-venue`, data);
};

export const updateTradingVenue = ( data: TradingVenueItem) => {
    return update<TradingVenueItem>(`/trading-venue/${data.id}`, data);
};

