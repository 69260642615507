import { get, create, update } from '../../lib/request'
import { Meta, UrlQueryItem } from '../api';

// Legal Entity
export interface ExampleEntityItem { 
  id: number;
  lastName?: string
  firstName?: string
  cityOfBirth?: string
  age?: number
  hairColor?: string
  }

export interface ExampleEntityListResponse {
  data?: ExampleEntityItem[];  
  meta?: Meta;
}

export const getExampleEntitiesList = ( query: UrlQueryItem[] = [] ) => {
  const queryString = query ? query.reduce( (value, item) => item.value != '' ? (item.field + '=' + item.value + '&' + value): value, "" ) : ""
  console.log(queryString)
  return get<ExampleEntityItem[]>(`/example-entities${ queryString != '' ? '?' + queryString : ""}`);
};

export const getExampleEntity = (id: string) => {
  return get<ExampleEntityItem>(`/example-entity/${id}`);
};

export const createExampleEntity = ( data:ExampleEntityItem ) => {
  return create<ExampleEntityItem>(`/example-entity`, data);
};

export const updateExampleEntity = ( data: ExampleEntityItem) => {
    return update<ExampleEntityItem>(`/example-entity/${data.id}`, data);
};
