import { useState, Fragment, FC, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Divider, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Stack, SxProps, Typography } from '@mui/material';
import { useNavigate, useParams } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form"


type FormValues = {
  title: string
  price: number
  description: string
  RestaurantId: number
}; 

type GenericFormProps = {
  onChanged: () => void
}

export default function GenericForm( props: GenericFormProps) {

  // const [ open, setOpen ] = useState(true)
  // const navigate = useNavigate()
  const { id } = useParams()  
  // const [ thisId, setThisId ] = useState ( id )

  // if (!thisId) { setThisId("new") } 
   
  const { register, handleSubmit, setValue, formState: { errors }, } = useForm<FormValues>( )
  // const [ data, setData ] = useState<FormValues>({ title: "", price: 0, description: "", RestaurantId: 3 })
  // const [ isLoading, setIsLoading ] = useState( id != 'new' )
  const [ isLoading, setIsLoading ] = useState( false )
  
  const onSubmit: SubmitHandler<FormValues> = (data: FormValues) => {
  //   console.log(data)
  //   setData(data)

  //   if (thisId == 'new') {


  //     const storedData = localStorage.getItem('current_restaurant_id');
  //     const restaurantId = storedData ? parseInt(storedData) : 1;

  //     console.log (restaurantId)

  //     createDish( {  ...data, RestaurantId: restaurantId, id: 0} ).then( ( json ) => { 
  //       setThisId( json.data.id.toString() )
  //      })
  //   } else {
  //     updateDish( { ...data, id: Number(thisId)  } )
  //   }

  }

  // useEffect ( () =>{

  //   if ( thisId != 'new' ) {
  //     getDish(thisId!).then((json) => {
  //       console.log(json);
  //       setValue( "title", json.data.title )
  //       setValue( "description", json.data.description )
  //       setValue( "price", json.data.price )
  //       // setIsLoading(false)
  //     }).finally(() => setIsLoading(false));
  //   }
    
  // }, []);

  const OnDialogClose = () => {
    return false;
  }

  const handleClose = () => {
    // setOpen(false);
    // navigate ('/dishes' )
    props.onChanged()


  };


  const sx: SxProps = {
    
    "& .MuiDialog-container": {
      alignItems: "flex-start",
      marginTop: 15
    }
 };

  return (
    <Fragment>

        { !isLoading && 
        
        <Dialog
          sx={sx}    
          open={true}
          onClose={ OnDialogClose}
          maxWidth='xl'
        >
          
          <form onSubmit={handleSubmit(onSubmit)}>        

        {/* <DialogTitle>{ id=='new' ? 'Neue Speise' : data.title }</DialogTitle> */}
        <DialogTitle>Create New Ticket</DialogTitle>
        <Divider />
        <Stack direction="row"
        > 
        <DialogContent sx={{ width: '35%'}} >

        </DialogContent>
        <DialogContent sx={{ width: '65%', bgcolor : '#f5f6f7'}}>
          <Typography variant='h6' sx={ {mb: 2}} >Ticket Type</Typography>
          <Paper sx={{ p:2 }} elevation={1}>
          <Box>
          <FormControl variant="filled" fullWidth sx={{ mb: 1 }}>

          <InputLabel id="demo-simple-select-filled-label">Business Areas</InputLabel>
          <Select
          label="Business Area"
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={null}
          onChange={() => {}}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>Employee & Securities Compliance</MenuItem>
          <MenuItem value={20}>Anti Money Launderind</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select>
</FormControl>
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="space-between" >
              <TextField
                sx={{ width: 'calc(50% - 10px)', bgcolor: '#FFFFFF' }}
                required
                autoFocus
                margin="dense"
                id="price"
                label="Category"
                type="text"
                variant="filled"
              // {...register("price")}
              />


              <TextField
                sx={{ width: 'calc(50% - 10px)', bgcolor: '#FFFFFF' }}
                required
                autoFocus
                margin="dense"
                id="price"
                label="Case"
                type="text"
                variant="filled"
              // {...register("price")}
              />
            </Box>

            

{/* 
          <DialogContentText>
            To subscribe to this website, please enter your email address here. We
            will send updates occasionally.
          </DialogContentText> */}

          
          </Paper>
        
          <Typography variant='h6' sx={ {my: 2}} >Details</Typography>

          <Grid container spacing={2}>
            <Grid item xs={6}>                    
              <TextField
              hiddenLabel
              required
              autoFocus
              margin="dense"
              id="title"
              type="text"
              fullWidth
              variant="filled"
              // {...register("title")}
            /> 
                 
            </Grid>

            <Grid item xs={6}>
                <TextField sx={ {bgcolor: '#FFFFFF'}}
                required
                autoFocus
                margin="dense"
                id="price"
                label="Preis"
                type="text"
                fullWidth
                variant="filled"
                // {...register("price")}
                />
            </Grid>
            
            <Grid item xs={12}>
                <TextField
                required
                autoFocus
                multiline
                margin="dense"
                id="description"
                label="Beschreibung"
                type="text"
                fullWidth
                variant="filled"
                // {...register("description")}
                //rows={4}
                maxRows={4}
                />
            </Grid>

          </Grid>

        </DialogContent>
        </Stack>



        <DialogActions>
          <Button onClick={handleClose}>Abbrechen</Button>
          <Button type="submit" variant='contained' >Speichern</Button>
        </DialogActions>
      </form>
      </Dialog> 
  
        }
    </Fragment>
  );
}

