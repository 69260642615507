import { get, create, update } from '../../lib/request'
import { LegalEntityItem } from '../api'

export const defaultLegalEntitiesList = [{
    id: 0,
    name: "",
    parentId: 0,
    status: "",
    displayColumn: 1,
    holdingPercentage: "0"
}]

export const getLegalEntitiesOrganigram = () => {
  return get<LegalEntityItem[]>(`/legal-entities/organigram`);
};
export const getLegalEntitiesList = () => {
  return get<LegalEntityItem[]>(`/legal-entities`);
};

export const getLegalEntity = (id: string) => {
  return get<LegalEntityItem>(`/legal-entity/${id}`);
};

export const createLegalEntity = ( data:LegalEntityItem ) => {
  return create<LegalEntityItem>(`/legal-entity`, data);
};

export const updateLegalEntity = ( data: LegalEntityItem) => {
    return update<LegalEntityItem>(`/legal-entity/${data.id}`, data);
};

