const handleBeforeUnload = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = '';
};

let hasUnsavedChanges = false;

const unsavedChanges = {
    set: (value: boolean) => {
        if (value !== hasUnsavedChanges) {
            hasUnsavedChanges = value;

            if (hasUnsavedChanges) {
                window.addEventListener('beforeunload', handleBeforeUnload);
            } else {
                window.removeEventListener('beforeunload', handleBeforeUnload);
            }
        }
    },
    get: () => hasUnsavedChanges,
};

export { unsavedChanges };
