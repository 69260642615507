import { useState, Fragment, FC, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Divider, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Stack, SxProps, Typography } from '@mui/material';
import { useNavigate, useParams } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form"
import { createTradingVenue, getTradingVenue, updateTradingVenue } from '../../api/TradingVenue';

type FormValues = {
  account: string
  name: string
  comment: string
}; 

type TradingVenueFormProps = {
  onChanged: () => void
}

export default function TradingVenueForm( props: TradingVenueFormProps) {

  //const [ open, setOpen ] = useState(true)
  const navigate = useNavigate()
  const { id } = useParams()  
  const [ thisId, setThisId ] = useState ( id )

  if (!thisId) { setThisId("new") } 
   
  const { getValues, register, handleSubmit, setValue, formState: { errors }, } = useForm<FormValues>( )
  const [ data, setData ] = useState<FormValues>({ name: "", account: "", comment: "" })
  const [ isLoading, setIsLoading ] = useState( id != 'new' )
  
  const onSubmit: SubmitHandler<FormValues> = (data: FormValues) => {
     setData(data)

     if (thisId == 'new') {
       createTradingVenue( {  ...data, id: 0} ).then( ( json ) => { 
         setThisId( json.data.id.toString() )
       })
     } else {
       updateTradingVenue( { ...data, id: Number(thisId)  } )
     }
  }

  useEffect ( () =>{
     if ( thisId != 'new' ) {
      getTradingVenue(thisId!).then((json) => {
        console.log(json);
        //setValue( {...json.data} )
        //update(0, json.data)
        setValue( "name", json.data.name )
        setValue( "account", json.data.account )
        setValue( "comment", json.data.comment || "" )
        setData( json.data as FormValues)
        setIsLoading(false)
      }).finally(() => setIsLoading(false));
    }
    }, []);

  const OnDialogClose = () => {
    return false;
  }

  const handleClose = () => {
    //setOpen(false);
    navigate ('/trading-venues' )
    //props.onChanged()


  };


  const sx: SxProps = {
    
    "& .MuiDialog-container": {
      alignItems: "flex-start",
      marginTop: 15
    }
 };

  return (
    <Fragment>

        { !isLoading && 
        
        <Dialog
          sx={sx}    
          open={true}
          onClose={ OnDialogClose}
          maxWidth='sm'
        >
          
        <form onSubmit={handleSubmit(onSubmit)}>        

        <DialogTitle>{ id=='new' ? 'New Trading Venue' : data.name + ' / ' + data.account}</DialogTitle>
        <Divider />
        <DialogContent sx={{  bgcolor : '#f5f6f7'}}>
          <Typography variant='h6' sx={ {mb: 2}} >Trading Venue</Typography>
          <Typography sx={ {mb: 2}} >Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing.</Typography>
          <Paper sx={{ p:2 }} elevation={1}>
          <Box>
            <TextField
              sx={{ width: '100%', bgcolor: '#FFFFFF' }}
              required
              autoFocus
              margin="dense"
              id="name"
              label={ getValues().name == undefined ? "Trading Venue (e.g. Kraken, Coinbase)" : "Trading Venue" }
              type="text"
              variant="filled"
              {...register("name")}
            />
          </Box>
          <Box>
            <TextField
              sx={{ width: '100%', bgcolor: '#FFFFFF' }}
              required
              autoFocus
              margin="dense"
              id="account"
              label={ getValues().account == undefined ? "Account Number (e.g. DF0234324, or john@doe.com)" : "Account Number" }
              type="text"
              variant="filled"
              {...register("account")}
            />          
            </Box>
            <Box>
            <TextField
                required
                autoFocus
                multiline
                margin="dense"
                id="description"
                label="Your personal comment"
                type="text"
                fullWidth
                variant="filled"
                {...register("comment")}
                rows={4}
                //maxRows={4}
                />
            </Box>

            
          
          </Paper>

        </DialogContent>

        <DialogActions sx={{ px: 3, py: 2}}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" variant='contained' >Save</Button>
        </DialogActions>
      </form>
      </Dialog> 
  
        }
    </Fragment>
  );
}

