import { Add, MoreVert } from '@mui/icons-material';
import { Badge, Box, Button, Divider, FormControl, Grid, IconButton, InputLabel, List, ListItem, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { defaultLegalEntitiesList, getLegalEntitiesOrganigram } from '../api/legalEntity';
import { useNavigate } from 'react-router-dom';
//import RestaurantForm from './RestaurantForm';
import { Route, Routes } from "react-router-dom"
import { LegalEntityItem } from '../api/api';
import { LegalEntityBox } from '../components/legalEntityBox';

function LegalEntityOrganigram() {

    const [ isLoading, setIsLoading] = useState (true)
    const data: LegalEntityItem[] = defaultLegalEntitiesList
    const [ listing, setListing] = useState<LegalEntityItem[]>( data );    
    const [ version, setVersion ] = useState<number>( 1 )
    const navigate = useNavigate();

    useEffect(() => {

        getLegalEntitiesOrganigram()
        .then((json) => {
          console.log(json);
          setListing(json.data)
        })
        .finally(() => setIsLoading(false));
        
      }, [version]);


    let x = 0;
    const dictY: any = {};

    if (isLoading) {
      return (<></>)
    }

    listing.forEach((item: LegalEntityItem) => { if (item.displayColumn) { dictY[item.displayColumn] = 125 } })
    const columnCount = listing.reduce( ( acc: number, item: LegalEntityItem) => { return Math.max( acc, item.displayColumn!)}, 0 ) 

    //const columnCount = Object.keys(dictY).length

    // const editRestaurant = (id: number) => {
    //   navigate ('/restaurants/' + id )
    // }
    
    // const createRestaurant = () => {
    //   navigate ('/restaurants/new' )
    // }
            
    // const onRestaurantSaveAndClose = () => {    
    //   setVersion(version + 1)
    // }

    return (    
<svg version="1.1" width="100%" height="1000" xmlns="http://www.w3.org/2000/svg">

<line strokeWidth="1" x1={(columnCount * (220) - 40) / 2} y1="50" x2={(columnCount * (220) - 40) / 2} y2="100" stroke="black" />

<foreignObject style={{ textAlign: 'left' }} x={0} y={0} width={columnCount * (220) - 40} height="100">
    <div className="card__background">
        <p className="card__text" style={{ fontSize: 20, fontWeight: 100 }}>Deutsche Börse AG</p>
    </div>
</foreignObject>

<line x1={0} y1="100" x2={(columnCount - 1) * 220 + 1} y2="100" stroke="black" />


{listing.map((item: LegalEntityItem) => {

    const returnElements = []
    const currentColumn = item.displayColumn ? item.displayColumn : 1

    x = (currentColumn - 1) * 220

    let y = dictY[currentColumn]
    dictY[currentColumn] += 120

    returnElements.push(<line x1={x + 1} y1={y + 60} x2={x + 25} y2={y + 60} stroke="black" />)
    returnElements.push(<line x1={x + 1} y1={100} x2={x + 1} y2={y + 60} stroke="black" />)
    returnElements.push(<LegalEntityBox {...item} x={x} y={y}  />)

    if (item.subLegalEntities) {

        item.subLegalEntities.map((item: LegalEntityItem, index: number) => {

            let y = dictY[currentColumn]
            dictY[currentColumn] += 120
            returnElements.push(<line x1={x + 14} y1={index === 0 ? y - 24 : y - 60} x2={x + 14} y2={y + 60} stroke="black" />)
            returnElements.push(<line x1={x + 14} y1={y + 60} x2={x + 25} y2={y + 60} stroke="black" />)
            returnElements.push(<LegalEntityBox {...item} indent x={x} y={y} />)

        })

    }


    return returnElements
}
)}
</svg>)

{/* 

      <Toolbar sx={{ borderBottom: 1, borderColor: 'RGB(0,0,0,0.12)', justifyContent: "space-between" }}>
        <Typography sx={{ color: 'RGB(1,1,1,0.8)' }} variant="h6">
          Restaurants
        </Typography>

        <Button size="small" onClick={() => { createRestaurant() }} variant="contained" startIcon={<Add />}>Erstellen</Button>

      </Toolbar>

      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow sx={{ backgroundColor: '#eeeeee' }}>
              <TableCell>Restaurant</TableCell>
              <TableCell>Stadt</TableCell>
              <TableCell>Contact</TableCell>
              <TableCell sx={{ width: 50 }}>Aktion</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading || listing.data.map((row) => (
              <TableRow hover key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} onClick={() => editRestaurant(row.id)}>
                <TableCell>{row.title}</TableCell>
                <TableCell>{row.city}</TableCell>
                <TableCell>{row.contact_name}</TableCell>
                <TableCell><IconButton><MoreVert /></IconButton></TableCell>
              </TableRow>
            ))}

          </TableBody>
        </Table>
      </TableContainer>

      <Routes>
        <Route path=":id" element={<RestaurantForm onChanged={onRestaurantSaveAndClose} />} />
      </Routes>

    </>; */}
  }

  export default LegalEntityOrganigram