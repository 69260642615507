import { get, create, update } from '../../lib/request'
import { StatusItem } from '../Status';
import { Meta, UrlQueryItem } from '../api';

// Legal Entity
export interface CryptoClearanceItem { 
  id: number;
  cryptoAsset?: string
  tradeVolumeEur?: number
  orderType?: string
  comment?: string
  Status?: StatusItem
  }

export interface CryptoClearanceListResponse {
  data?: CryptoClearanceItem[];  
  meta?: Meta;
}

export const getCryptoClearancesList = ( query: UrlQueryItem[] = [] ) => {
  const queryString = query ? query.reduce( (value, item) => item.value != '' ? (item.field + '=' + item.value + '&' + value): value, "" ) : ""
  console.log(queryString)
  return get<CryptoClearanceItem[]>(`/crypto-clearances${ queryString != '' ? '?' + queryString : ""}`);
};

export const getCryptoClearance = (id: string) => {
  return get<CryptoClearanceItem>(`/crypto-clearance/${id}`);
};

export const createCryptoClearance = ( data:CryptoClearanceItem ) => {
  return create<CryptoClearanceItem>(`/crypto-clearance`, data);
};

export const updateCryptoClearance = ( data: CryptoClearanceItem) => {
    return update<CryptoClearanceItem>(`/crypto-clearance/${data.id}`, data);
};
