import React, { useEffect, useState } from 'react';

import './App.css';

import { DbgHeader } from './dbg/components/header';
import { DbgHorizontalNavigation } from './dbg/components/horizontalNavigation';
import { Route, Routes } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import LegalEntityOrganigram from './pages/LegalEntityOrganigram';
import GenericForm from './components/genericForm';
import { CryptoAssetListing } from './pages/CryptoAssetListing';
import { TradingVenueListing } from './pages/TradingVenueListing';
import { Box } from '@mui/material';
import { ExampleEntityListing } from './pages/ExampleEntityListing';
import { CryptoClearanceListing } from './pages/CryptoClearanceListing';
import { AssetListing } from './pages/AssetListing';
import { getMe } from './api/Me';
import { setToken } from './lib/request';

function App() {

  const { isLoading, isAuthenticated, loginWithRedirect, user, getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  const [isUserInfoLoaded, setIsUserInfoLoaded ] = useState ( false )

  useEffect(() => {

      if ( isAuthenticated ) {
      getAccessTokenSilently( ).then( (accessToken) => {

        setToken(accessToken)

        getMe( accessToken).then( ( response ) => {
          
          console.log('Response', response)       
          
          if (response.data.permissions!.indexOf("user:compliance") > -1) {
            localStorage.setItem( 'activeRole', 'Compliance Officer' )  
          } else {
            localStorage.setItem( 'activeRole', 'User' )  
          }
          
          localStorage.setItem( 'name', response.data.user )
          localStorage.setItem( 'roles', response.data.permissions!.toString()) 
          
          setIsUserInfoLoaded(true)
        })
    })}}
  , [isAuthenticated ])

  if (isLoading) {
    return (
      <div className="page-layout">
      </div>
    );
  }

  if ( !isAuthenticated ) {

    console.log( 'not authenticated')

    loginWithRedirect({
      appState: {
        returnTo: "/auth/organigram",
      },
    });

  } else { console.log (user )}


  return (isAuthenticated && isUserInfoLoaded) ? (

    <div className="App" >
      <DbgHeader />
      <DbgHorizontalNavigation items={
        [
          {label: 'Home', route: 'home'},
          {label: 'Clearance', route: 'crypto-clearances'},
          {label: 'Transactions', route: 'transactions'},
          {label: 'Trading Venues', route: 'trading-venues'},
          {label: 'Crypto Assets', route: 'crypto-assets'},
          {label: 'Assets', route: 'assets', requiredPermission: ['crypto-assets:list']},
          {label: 'Generated Entities', route: 'example-entities'},
          
        ]} />
            
      <Box sx={{}}>
        <Routes>
          <Route path="/home" element={<></>} />
          <Route path="/crypto-assets/*" element={<CryptoAssetListing />} />
          <Route path="/assets/*" element={<AssetListing />} />
          <Route path="/crypto-clearances/*" element={<CryptoClearanceListing />} />
          <Route path="/organigram/*" element={<LegalEntityOrganigram />} />
          <Route path="/trading-venues/*" element={<TradingVenueListing />} />
          <Route path="/example-entities/*" element={<ExampleEntityListing />} />
          <Route path="/form/" element={<GenericForm onChanged={function (): void {throw new Error('Function not implemented.');} } />} />
          {/* <Route path="/restaurant-map/*" element={<RestaurantMap />} />
          <Route path="/services/*" element={<DishListing restaurantId={restaurantId} />} /> */}
        </Routes>        
      </Box>
    </div>
  ) : <></>;
}

export default App;


