import { useAuth0 } from "@auth0/auth0-react";
import { Box, Tabs, Tab } from "@mui/material"
import { useState } from "react";
import { useNavigate } from 'react-router-dom';

const hasRole = ( role: string ) => {
    return localStorage.getItem('roles')?.includes (role)
}

const hasAnyRole = ( roles: string[] ) => {
    
    for (let index = 0; index < roles.length; index++) {
        const role = roles[index];
        if (hasRole(role)) {
            return true
        }        
    }

    return false
}


interface DbgHorizontalNavItem {
    label: string;
    route: string;
    requiredPermission?: string[];
}

interface DbgHorizontalNavProps {
    items: DbgHorizontalNavItem[]
}

function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

export const DbgHorizontalNavigation = ( props: DbgHorizontalNavProps ) => {

    const [value, setValue] = useState(0);
    const navigate = useNavigate();
    const {user} = useAuth0();

    console.log ( user?.permissions )

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
        navigate(event.currentTarget.getAttribute('data-route') as string)
    };

    let i = 0

    return (
        <Box paddingLeft={2} sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                
                { props.items.map( ( item: DbgHorizontalNavItem ) => (!item.requiredPermission ||  hasAnyRole(item.requiredPermission)) ? <Tab sx={{ textTransform: 'none'}} data-route={item.route} label={item.label} {...a11yProps(i++)} /> : null)}
                
                {/* <Tab sx={{ textTransform: 'none', paddingLeft: '0' }} label="Processed Transactions" {...a11yProps(1)} />
                <Tab sx={{ textTransform: 'none', paddingLeft: '0' }} label="All Transactions" {...a11yProps(2)} /> */}
            </Tabs>
        </Box>

    )
}