import { ServerResponse } from './interface';

export const BASE: string = process.env.REACT_APP_AUTH0_AUDIENCE ? process.env.REACT_APP_AUTH0_AUDIENCE : 'http://localhost:8080/api';

const ERROR_MESSAGE: { [key: number]: string } = {
    // 400: '...',
    // 401: '...',
    403: "You don't have permissions to access this information!",
    404: 'Failed to fetch: Endpoint not found!',
    409: 'Conflict',
    500: 'Something went wrong!',
};

export function buildUrl(path: string) {
    if (path.startsWith(BASE)) {
        return path;
    }

    return `${BASE.replace(/\/$/, '')}/${path.replace(/^\//, '')}`;
}

/**
 * Tries to unpack response sent from the server and process basic errors
 * @param response Response returned from "fetch"
 */
export function unpackResponse<T>(response: Response): Promise<ServerResponse<T>> {
    return response
        .json()
        .then((json: ServerResponse<T>) => {
            switch (response.status) {
                case 200:
                case 201:
                    return json;

                case 400:
                case 401:
                    throw json;

                default:
                    const defaultMessage = ERROR_MESSAGE[response.status]
                        ? ERROR_MESSAGE[response.status]
                        : ERROR_MESSAGE[500];
                    throw new Error(json.meta.error || defaultMessage);
            }
        })
        .catch((e) => {
            if (e.data || e.message || e.meta) throw e;
            throw new Error(ERROR_MESSAGE[500]);
        });
}
