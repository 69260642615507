import { Box, Button, Link, Pagination, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { DataGrid, GridCallbackDetails, GridColDef, GridColumnOrderChangeParams, MuiEvent } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import './styles.css';
import { TradingVenueListResponse, getTradingVenuesList } from '../../api/TradingVenue';
import { useEffect, useState } from 'react';
import { DbgFilterArea, DbgFilterAreaProps, DbgSearchFilterField } from '../../dbg/components/FilterArea';
import { Route, Routes, useNavigate } from 'react-router-dom';
import TradingVenueForm from '../TradingVenueForm';
import { Paging, UrlQueryItem } from '../../api/api';


export function TradingVenueListing() {

  const [ isLoading, setIsLoading] = useState (true)
  const [ paging, setPaging ] = useState<Paging> ( {} )
  const [ query, setQuery ] = useState<UrlQueryItem[]>( [] )
  const [ page, setPage ] = useState( 1 )
  const [ listing, setListing] = useState<TradingVenueListResponse>( {} );    
  const [ version, setVersion ] = useState<number>( 1 )
  const navigate = useNavigate();

  const filterAreaConfig:DbgFilterAreaProps = {
    caption: "Filter your Trading Venues",
    fields: [ 
      { name: 'name', label: "Trading Venue", fieldtype: "text"},
      { name: 'account', label: "Account", fieldtype: "text"}      
    ],
    query: query,
    onFilter: ( filter:UrlQueryItem[] ) => {onFilter(filter)}
  }

  const onFilter = ( filter:UrlQueryItem[]  ) =>  {            
    setPage(1)
    setQuery( [...filter ] ) 
  }

  useEffect(() => {

    getTradingVenuesList([...query] )
    .then((json) => {
      setListing(json)
    })
    .finally(() => setIsLoading(false));
    
  }, [query, version]);

  const createItem = () => {
    navigate ('/trading-venues/new' )
  }

  const editItem = (id: number) => {
    navigate ('/trading-venues/' + id )
  }
  
  const onPage = (event: any, page: number) => {

    setPage(page)
    
    let newUrlQuery = query
    const newItem = { field: 'page', value: page.toString()}

    if (! newUrlQuery) {
      newUrlQuery= [newItem]
    } else {
      const found = newUrlQuery.find((element) => element.field == 'page');

      if (!found ) {
          newUrlQuery.push(newItem)
      } else {
          newUrlQuery = newUrlQuery.map( (item) => item.field == 'page' ? newItem : item )
      }
      
    }

    setQuery([...newUrlQuery])


  }

      return (
      <Box sx={{}}>
        <DbgFilterArea {...filterAreaConfig} />  
      <Box sx={{bgcolor: '#f5f6f7', m: 0, py: 2, px: 3, borderTop: '1px solid #e1e1e2'}}>

        <Box sx={{ width: '100%', bgcolor: '#f5f6f7' }}>
            <Box sx={{ bgcolor: '#FFFFFF', border: '1px solid #e1e1e2', py: 1, px: 2, display: "flex", flexDirection: "row", justifyContent: 'flex-end'}}>
                <Button onClick={() => { createItem() }} startIcon={<AddIcon />} variant="contained" sx={{   }}>Trading Venue</Button>
            </Box>
            <TableContainer >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow sx={{bgcolor: '#f5f5f5', borderLeft: '1px solid #e0e0e0', borderRight: '1px solid #e0e0e0'}}>
            <TableCell>Trading Venue</TableCell>
            <TableCell>Account</TableCell>
            <TableCell>Created</TableCell>
            <TableCell>Updated</TableCell>
          </TableRow>
        </TableHead>
        
         { listing && listing.data &&

        <TableBody>
          {listing.data.map((item) => (
            <TableRow              
              key={ item.id}
              sx={{ bgcolor: '#FFFFFF', borderLeft: '1px solid #e0e0e0', borderRight: '1px solid #e0e0e0' }}
              
            >
              <TableCell>{item.name}</TableCell>
              <TableCell><Link onClick={() => editItem(item.id)}>{item.account}</Link></TableCell>
              <TableCell>{item.createdAt}</TableCell>
              <TableCell>{item.updatedAt}</TableCell>
              
            </TableRow>
          ))}
        </TableBody>

        }

      </Table>

      { listing.meta?.paging && listing.meta?.paging.totalPages && listing.meta?.paging.totalPages > 1 && 

      <Stack display="flex" justifyContent="center" flexDirection="row" spacing={2} sx={{my:4}}>
        <Pagination size='large'  shape="rounded" page={page} count={listing.meta.paging.totalPages} color="primary" onChange={onPage} />
      </Stack>
}
    </TableContainer>
      </Box>
    </Box>

    <Routes>
        <Route path=":id" element={<TradingVenueForm onChanged={function (): void {
              throw new Error('Function not implemented.');
            } } />} />
      </Routes>

  </Box>);

}