import { buildUrl, unpackResponse } from './utils';
import { getToken } from './access-token';
import { ServerResponse } from './interface';
import { useAuth0 } from "@auth0/auth0-react";

export function requestBare<Response, Body = Response>(
    url: string,
    {
        method,
        body,
        token,
    }: {
        method: string;
        body?: Body;
        token?: string;
    },
) {

    
    const accessToken: string = token? token: getToken() as string
    
    return fetch(buildUrl(url), {
        method,
        headers: new Headers({
            'Content-Type': 'application/json',             
            ...(accessToken) && {Authorization: `Bearer ${accessToken}`}
        })
        ,
        body: body && JSON.stringify(body),
    });
}

export function request<Response, Body = Response>( url: string, { method, body, token}: { method: string; body?: Body; token?: string}, ) {
    return requestBare(url, { method, body, token }).then<ServerResponse<Response>>(unpackResponse);
}

export function get<Response>(url: string, token?: string) {
    return request<Response>(url, { method: 'GET', token });
}

export function create<Response, Body = Response>(url: string, body: Body, token?: string) {
    return request<Response, Body>(url, { method: 'POST', body, token });
}

export function update<Response, Body = Response>(url: string, body: Body) {
    return request<Response, Body>(url, { method: 'PUT', body });
}

// export function getSingle<Response> ( url: string): Promise<Response>  {

//     return fetch(buildUrl(url), {
//         method: 'GET',
//         headers: new Headers({
//             'Content-Type': 'application/json',
//             Authentication: `${getTokenHardCoded()}`,
//             //Authorization: `Bearer ${getToken()}`,
//         })
//     });


// }