import { Box, Button, Typography } from '@mui/material';
import { DataGrid, GridCallbackDetails, GridColDef, GridColumnOrderChangeParams, MuiEvent } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import './styles.css';

export function CryptoAssetListing() {


    const onColOrderChange = ( params: GridColumnOrderChangeParams, event: MuiEvent<{}>, details: GridCallbackDetails ) => {

        console.log( 'onColOrderChange')

    }

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', width: 70, headerClassName: 'super-app-theme--header',  },
        // { field: 'firstName', headerName: 'First name', width: 130, headerClassName: 'super-app-theme--header' },
        { field: 'lastName', headerName: 'Last name', width: 130 , headerClassName: 'super-app-theme--header'},
        { field: 'firstName', headerName: 'marcus', width: 130 , headerClassName: 'super-app-theme--header', renderCell: (row) => { console.log(row); return (<Button variant="outlined" size='small'>{row.field || ''} </Button>)}},
        {
          field: 'age',
          headerName: 'Age',
          type: 'number',
          width: 90,
          headerClassName: 'super-app-theme--header'
        },
        {
          field: 'fullName',
          headerName: 'Full name',
          description: 'This column has a value getter and is not sortable.',
          sortable: false,
          width: 160,
          // valueGetter: (value, row) => `${row.firstName || ''} ${row.lastName || ''}`,
          headerClassName: 'super-app-theme--header'
        },
      ];
      
      const rows = [
        { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
        { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
        { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
        { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
        { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
        { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
        { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
        { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
        { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
      ];

      return (
        <Box sx={{ height: 400, width: '100%', bgcolor: '#f5f6f7' }}>
            <Box sx={{ bgcolor: '#FFFFFF', border: '1px solid #e1e1e2', py: 1, px: 2, display: "flex", flexDirection: "row", justifyContent: 'flex-end'}}>
                <Button startIcon={<AddIcon />} variant="contained" sx={{   }}>Transaction</Button>
            </Box>
          <DataGrid
            rows={rows}
            columns={columns}
            onColumnOrderChange={onColOrderChange}
            getRowClassName={ (params) => 'super-app-theme--row'}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection
          />
        </Box>
      );

}